<template>
    <b-form
        @submit.prevent="onSubmit"
        :novalidate="true"
    >
        <div class="row">
            <div class="col-12 col-md-6">
                <form-input-select
                    :id="'realOwners.select.selectPerson'"
                    :label="$t('realOwners.select.selectPerson')"
                    v-model="personId"
                    :options="companyOwnersOptions"
                    :validation="$v.personId"
                />
            </div>
        </div>

        <form-input-checkbox-group
            :id="'realOwners.select.personCategories'"
            :label="$t('realOwners.form.personCategories')"
            v-model="personCategories"
            :options="personCategoriesOptions"
            :validation="$v.personCategories"
            :stacked="false"
        />

        <div class="row">
            <div
                v-if="isStatutory"
                class="col-12 col-md-6"
            >
                <form-input-select
                    :id="'realOwners.select.statutoryType'"
                    :label="$t('realOwners.statutoryType')"
                    v-model="statutoryType"
                    :options="statutoryTypes"
                    :validation="$v.statutoryType"
                />
            </div>
            <div
                v-if="isRealOwner"
                class="col-12 col-md-6"
            >
                <form-input
                    :id="'realOwners.select.relationshipDetail'"
                    :label="$t('realOwners.form.relationshipDetail')"
                    v-model="relationshipDetail"
                    :hint="$t('realOwners.form.relationshipDetailReason')"
                    :validation="$v.relationshipDetail"
                />
            </div>
        </div>

        <p class="mt-4">
            <b-btn
                type="submit"
                variant="primary"
                :disabled="$v.$invalid"
                size="sm"
                class="py-26"
            >
                {{ $t('realOwners.select.submit') }}
            </b-btn>
        </p>

    </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'RealOwnerSelectForm',
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        realOwnerTypes: {
            type: Array,
            required: true
        },
        companyOwners: {
            type: Array,
            required: true
        },
        realOwnerUndeterminable: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            personId: null,
            relationshipDetail: null,
            isRealOwner: false,
            isStatutory: false,
            statutoryType: null
        }
    },
    computed: {
        ...mapGetters(['statutoryTypes']),
        companyOwnersOptions () {
            if (this.companyOwners.length > 0) {
                return this.companyOwners.map((companyOwner) => {
                    const birtIdentification = companyOwner.noRc ? new Date(companyOwner.birthDate).toLocaleDateString() : companyOwner.rc
                    return {
                        value: companyOwner._id,
                        text: `${companyOwner.name} ${companyOwner.surname} (${companyOwner.jobFunction}) ${birtIdentification}`
                    }
                })
            }
            return []
        },
        personCategories: {
            get () {
                return [
                    this.isRealOwner ? 'isRealOwner' : undefined,
                    this.isStatutory ? 'isStatutory' : undefined
                ].filter(item => item)
            },
            set (value) {
                this.isRealOwner = value.includes('isRealOwner')
                this.isStatutory = value.includes('isStatutory')
            }
        },
        personCategoriesOptions () {
            return [
                {
                    value: 'isStatutory',
                    text: this.$t('realOwners.form.isStatutory')
                },
                {
                    value: 'isRealOwner',
                    text: this.$t('realOwners.form.isRealOwner'),
                    disabled: this.realOwnerUndeterminable === true
                }
            ]
        }
    },
    methods: {
        async onSubmit () {
            this.$v.$reset()
            await this.$nextTick()
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('errors.someDataIsMissing'))
                this.$v.$touch()
                return
            }
            const owner = this.companyOwners.find(item => item._id === this.personId)
            const realOwner = {
                name: owner.name,
                surname: owner.surname,
                birthDate: owner.birthDate,
                citizenship: owner.citizenship,
                addressStreet: owner.addressStreet,
                addressCity: owner.addressCity,
                addressZip: owner.addressZip,
                addressCountry: owner.addressCountry,
                politician: owner.politician,
                countryOrigin: owner.countryOrigin,
                relationshipDetail: this.relationshipDetail,
                isRealOwner: this.isRealOwner,
                isStatutory: this.isStatutory,
                statutoryType: this.statutoryType
            }
            this.$v.$reset()
            this.$emit('submit', realOwner)
        }
    },
    validations: {
        personCategories: {
            required
        },
        statutoryType: {
            required: requiredIf(function () {
                return this.isStatutory
            })
        },
        relationshipDetail: {
            required: requiredIf(function () {
                return this.isRealOwner
            })
        },
        personId: {
            required
        }
    }
}
</script>
<style scoped>
.align-items-last-baseline {
    align-items: last baseline;
}
.py-26 {
    padding-top: .6rem;
    padding-bottom: .6rem;
}
</style>
